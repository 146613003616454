

<div [ngStyle]="{'display': (isLoading) ? 'block' : 'none'}">
<mat-spinner></mat-spinner>
</div>
<div class="col-md-12" [ngStyle]="{'display': (isLoading) ? 'none' : 'block'}">


<h2>Ay Bazlı Rapor</h2>
<hr />


<div class="filtermenu">
<button (click)="changeYear(currentYear)" id="btn{{currentYear}}" class="btn btn-dark active">{{ currentYear }}</button>
<div style="width:10%;"></div>
<button *ngFor="let item of years" (click)="changeYear(item)" class="btn btn-dark" id="btn{{item}}">{{ item }}</button>
</div>

<div style="margin-top:5%;"></div>

<div *ngFor="let item of dataBlocks" class="row">
<div (click)="changeMonth(item.id)" class="monthBlock">
<label class="header">{{ item.title }} {{ selectedYear }}</label>
<label class="subheader">Kar: {{ item.totalProfit | currency:'TRY'}}</label>
<span class="collapseicon"><i class="fas fa-chevron-down" id="collapse{{item.id}}"></i></span>
</div>
<div class="monthBlockDetail" id="monthBlockDetail{{item.id}}" style="display:none;">
    <table class="table">
        <thead>
            <tr>
                <th>Plaka</th>
                <th>Marka</th>
                <th>Model</th>     
                <th>Masraf</th>                                             
                <th>Tutar</th>
                <th>Kar</th>
                <th>İşlemler</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let dataItem of item.data">
                <td>{{dataItem.plate}}</td>
                <td>{{dataItem.make}}</td>
                <td>{{dataItem.model}}</td>
                <td *ngIf="dataItem.expense !== null">{{dataItem.expense | currency:'TRY'}}</td>
                <td *ngIf="dataItem.expense === null"></td>
                <td *ngIf="dataItem.income !== null">{{dataItem.discountedIncome | currency:'TRY'}}</td>
                <td *ngIf="dataItem.income === null"></td>
                <td *ngIf="dataItem.profit !== null">{{dataItem.profit | currency:'TRY'}}</td>
                <td *ngIf="dataItem.profit === null"></td>
                <td>
                    <span (click)="redirectToInfo(dataItem.idNumber)" class="btn btn-primary"><i class="fa fa-info"></i></span>          
                </td> 
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td class="subtitle">{{ getTotalExpense(item.data) | currency:'TRY'}}</td>
                <td class="subtitle">{{ getTotalDiscountedIncome(item.data) | currency:'TRY'}}</td>
                <td class="subtitle">{{ getTotalProfit(item.data) | currency:'TRY'}}</td>
            </tr>
        </tbody>
    </table>
</div>
</div>

</div>