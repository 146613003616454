import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-month-based-report',
  templateUrl: './month-based-report.component.html',
  styleUrls: ['./month-based-report.component.css']
})
export class MonthBasedReportComponent implements OnInit {

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) { }

  isLoading: boolean = true;

  private API_URL = environment.API_URL;

  years: Number[];
  currentYear: Number;
  selectedYear: Number;
  selectedMonth: Number;
  months: string[];
  dataBlocks: Object[];

  ngOnInit(): void {
    let control = localStorage.getItem('type');
    if(control === null || control == 'false') {
      localStorage.removeItem('token');
      this.router.navigateByUrl('/');
      window.location.reload();
    } else {
    this.years = [];
    this.currentYear = new Date().getFullYear();
    this.selectedYear = this.currentYear;
    this.selectedMonth = null;
    this.months = ['', 'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];
    this.dataBlocks = [];
    this.setYears();
    this.getReportData(this.currentYear);
    }
  }

  setYears() {
    for(let i = 2021; i < this.currentYear; i++) {
      this.years.push(i);
    }
    this.years.reverse();
  }

  getReportData(year) {

    this.isLoading = true;
    
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");  
    
    this.httpClient.get(
      this.API_URL + "workshopCar/" + year
    ).subscribe((res: Object[]) => {
      if(res && res[0] !== null) {

        for(let i = 1; i <= 12; i++) {
          let obj = {
            id: i,
            title: this.months[i]
          };
          
          let monthData = res.filter(x => new Date(x['purchaseDate']).getFullYear() == year && (new Date(x['purchaseDate']).getMonth() + 1) == i);

          obj['totalProfit'] = 0;
          monthData.forEach((item) => {
              obj['totalProfit'] += item['profit'];
          });
          obj['data'] = monthData;

          this.dataBlocks.push(obj);

        }

      }      
    });

    this.isLoading = false;
    
  }

  changeYear(year) {
    document.getElementById('btn' + this.selectedYear).classList.remove('active');
    this.selectedYear = year;
    document.getElementById('btn' + year).classList.add('active');
    this.dataBlocks = [];
    this.selectedMonth = null;
    this.getReportData(year);
  }

  changeMonth(monthId) {
    if(this.selectedMonth !== null && this.selectedMonth !== undefined) {
    document.getElementById('collapse' + this.selectedMonth).classList.remove('fa-chevron-up');
    document.getElementById('collapse' + this.selectedMonth).classList.add('fa-chevron-down');
    document.getElementById('monthBlockDetail' + this.selectedMonth).style.display = "none";
    }

    this.selectedMonth = monthId;
    document.getElementById('collapse' + this.selectedMonth).classList.remove('fa-chevron-down');
    document.getElementById('collapse' + this.selectedMonth).classList.add('fa-chevron-up');
    document.getElementById('monthBlockDetail' + this.selectedMonth).style.display = "block";
  }

  getTotalExpense(data: Object[]) {
    if(data !== null && data !== undefined && data && data.length > 0) {
      let res = 0;
      data.forEach((x) => {
        res += x['expense'];
      });
      return res;
    } else {
      return 0;
    }
  }

  getTotalDiscountedIncome(data: Object[]) {
    if(data !== null && data !== undefined && data && data.length > 0) {
      let res = 0;
      data.forEach((x) => {
        res += x['discountedIncome'];
      });
      return res;
    } else {
      return 0;
    }
  }

  getTotalProfit(data: Object[]) {
    if(data !== null && data !== undefined && data && data.length > 0) {
      let res = 0;
      data.forEach((x) => {
        res += x['profit'];
      });
      return res;
    } else {
      return 0;
    }
  }

  redirectToInfo(id) {
    this.router.navigateByUrl('/arac-detay/' + id);
  }

}
