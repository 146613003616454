import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-end-of-month-report',
  templateUrl: './end-of-month-report.component.html',
  styleUrls: ['./end-of-month-report.component.css']
})
export class EndOfMonthReportComponent implements OnInit {

  constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private httpClient: HttpClient,
  ) { }

  endOfMonthReportForm: FormGroup;
  isSubmitted: boolean;
  result: Object;

  private API_URL = environment.API_URL;

  ngOnInit(): void {
    let control = localStorage.getItem('type');
    if(control === null || control == 'false') {
      localStorage.removeItem('token');
      this.router.navigateByUrl('/');
      window.location.reload();
    } else {

    this.isSubmitted = false;

    this.endOfMonthReportForm = this.formBuilder.group({
      reportMonth: ['1', Validators.compose([Validators.required])],
      reportYear: ['0', Validators.compose([Validators.required])],
      rentPrice: ['0', Validators.compose([Validators.required])],
      billPrice: ['0', Validators.compose([Validators.required])],
      employeePrice: ['0', Validators.compose([Validators.required])],
      adittionalExpense: ['0', Validators.compose([Validators.required])]  
    });

    let selectyear = document.getElementById('selectyear');
    let year = new Date().getFullYear();

    for(let i = 2021; i <= year; i++) {
      let opt = document.createElement('option');
      opt.innerHTML = String(i);
      selectyear.appendChild(opt);
    }

    this.endOfMonthReportForm.controls.reportYear.setValue('2021');
    }
  }

  submitForm() {
    this.isSubmitted = true;
    if(this.endOfMonthReportForm.valid) {

      const year = this.endOfMonthReportForm.controls.reportYear.value;
      const month = this.endOfMonthReportForm.controls.reportMonth.value;

      let headers = new HttpHeaders();
      headers = headers.append("Content-Type", "application/json");    
      this.httpClient.post(
        this.API_URL + "workshopCar/endreport/" + year + "/" + month,
          { 
            "rentPrice": this.endOfMonthReportForm.controls.rentPrice.value,
            "billPrice": this.endOfMonthReportForm.controls.billPrice.value,
            "employeePrice": this.endOfMonthReportForm.controls.employeePrice.value,
            "adittionalExpense": this.endOfMonthReportForm.controls.adittionalExpense.value
          }, 
          { headers: headers }
          ).subscribe((res: Object) => {
            this.result = res;
          });



    } else {
      alert('Lütfen tüm formu doldurunuz.');
    }
  }


}
