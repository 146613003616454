import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { CustomDateFormatter } from '../formatters/customDateFormatter';

@Component({
  selector: 'app-reservation-add',
  templateUrl: './reservation-add.component.html',
  styleUrls: ['./reservation-add.component.css'],
  providers: [{provide: NgbDateParserFormatter, useClass: CustomDateFormatter}]
})
export class ReservationAddComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
    private httpClient: HttpClient,
    private router: Router
  ) { 
    this.dateAdapter.setLocale("tr");
  }

  private API_URL = environment.API_URL;
  isSubmitted: boolean = false;
  addReservationForm: FormGroup;
  pipe: DatePipe;

  ngOnInit(): void {
    this.pipe = new DatePipe('tr-TR');
    this.addReservationForm = this.formBuilder.group({
      phoneNumber: ['', Validators.compose([Validators.required, Validators.maxLength(11), Validators.minLength(11)])],
      plate: ['', Validators.compose([Validators.required])],
      fullname: ['', Validators.compose([Validators.required])],
      make: ['', Validators.compose([Validators.required])],
      model: ['', Validators.compose([Validators.required])],
      reservationDateText: ['', Validators.compose([Validators.required])],
      reservationDateHour: ['', Validators.compose([Validators.required])],
      homedescription: ['', Validators.compose([])],
    });
  }

  addReservation() {
    if(this.addReservationForm.valid) {

      let date;

      try {
        const reservationHourArray = this.addReservationForm.controls.reservationDateHour.value.split(':');
        let obj = this.addReservationForm.controls.reservationDateText.value;
        obj.hour = reservationHourArray[0];
        obj.minutes = reservationHourArray[1];
        console.log('objjj: ', obj);
        // date = new Date(`${obj.year}-${obj.month}-${obj.day}T${obj.hour}:${obj.minutes}:00`);
        date = new Date(obj.year, (obj.month - 1), obj.day, obj.hour, obj.minutes, 0);
        if(isNaN(date.getTime())) {
          throw new Error();
        }

        let headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");

        this.httpClient.post(
          this.API_URL + "reservation/add",
            { 
                plate: this.addReservationForm.controls.plate.value.replace(/\s/g, "").toUpperCase(),
                fullname: this.addReservationForm.controls.fullname.value.toUpperCase(),
                phoneNumber: this.addReservationForm.controls.phoneNumber.value,
                reservationDate: date,
                description: this.addReservationForm.controls.homedescription.value,
                make: this.addReservationForm.controls.make.value.toUpperCase(),
                model: this.addReservationForm.controls.model.value.toUpperCase(),
            }, 
            { headers: headers }
        ).subscribe((res) => {
            alert("Rezervasyon başarıyla eklendi.");
            this.router.navigateByUrl("/rezervasyon-liste");
        }); 

      } catch(ex) {
        console.log('Rezervasyon tarihi ve saati yanlış girildi: ', ex);
      }      

    } else {
      this.isSubmitted = true;
      console.log(this.addReservationForm.errors);
    }

  }

}
