import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(
    private router: Router
  ) {}
  title = 'borzatolye-panel';
  isLoggedIn: boolean = localStorage.getItem("token") !== null ? true : false;
  type: boolean;

  ngOnInit() {
    if(!this.isLoggedIn)
    {
      this.router.navigateByUrl("/");
    }

    if(window.location.pathname !== '/' && !localStorage.getItem('type')) {
      localStorage.removeItem("token");
      this.router.navigateByUrl("/");
      window.location.reload();
    }

    this.type = localStorage.getItem('type') === 'true' ? true : false;
  }


  signOut() {
    localStorage.removeItem("token");
    this.router.navigateByUrl("/");
    window.location.reload();
  }
}
