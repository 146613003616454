

<div class="col-md-12">
    <h2>Rezervasyon Ekle</h2>
    <hr />
    <form [formGroup]="addReservationForm">
        <div class="row">
            <div class="col-md-6">
                <label>Rezervasyon Tarihi</label>
                <div class="form-group">
                    <div class="input-group">
                    <input class="form-control"
                            name="dp" formControlName="reservationDateText" ngbDatepicker #d="ngbDatepicker" style="margin-right:1%">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i class="far fa-clock"></i></button>
                    </div>
                    </div>
                </div>
                <div *ngIf="(isSubmitted || addReservationForm.controls.reservationDateText.dirty || addReservationForm.controls.reservationDateText.touched) && addReservationForm.controls.reservationDateText.errors?.required" class="alert alert-danger" style="margin-top:1%;">
                    Rezervasyon tarihi girilmesi zorunludur.
                </div>
            </div>
            <div class="col-md-6">
                <label>Rezervasyon Saati</label>
                <input class="form-control" type="text" formControlName="reservationDateHour" placeholder="16:30">
                <div *ngIf="(isSubmitted || addReservationForm.controls.reservationDateHour.dirty || addReservationForm.controls.reservationDateHour.touched) && addReservationForm.controls.reservationDateHour.errors?.required" class="alert alert-danger" style="margin-top:1%;">
                    Rezervasyon saati girilmesi zorunludur.
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label>Ad Soyad</label>
                <input class="form-control" type="text" formControlName="fullname" style="text-transform: uppercase;">
                <div *ngIf="(isSubmitted || addReservationForm.controls.fullname.dirty || addReservationForm.controls.fullname.touched) && addReservationForm.controls.fullname.errors?.required" class="alert alert-danger" style="margin-top:1%;">
                    Ad Soyad girilmesi zorunludur.
                </div>
            </div>
            <div class="col-md-6">
                <label>Plaka</label>
                <input class="form-control" type="text" formControlName="plate" style="text-transform: uppercase;">
                <div *ngIf="(isSubmitted || addReservationForm.controls.plate.dirty || addReservationForm.controls.plate.touched) && addReservationForm.controls.plate.errors?.required" class="alert alert-danger" style="margin-top:1%;">
                    Plaka girilmesi zorunludur.
                </div>
            </div>    
        </div>
        <div class="row">
            <div class="col-md-6">
                <label>Marka</label>
                <input class="form-control" type="text" formControlName="make" style="text-transform: uppercase;">
                <div *ngIf="(isSubmitted || addReservationForm.controls.make.dirty || addReservationForm.controls.make.touched) && addReservationForm.controls.make.errors?.required" class="alert alert-danger" style="margin-top:1%;">
                    Marka girilmesi zorunludur.
                </div>
            </div>
            <div class="col-md-6">
                <label>Model</label>
                <input class="form-control" type="text" formControlName="model" style="text-transform: uppercase;">
                <div *ngIf="(isSubmitted || addReservationForm.controls.model.dirty || addReservationForm.controls.model.touched) && addReservationForm.controls.model.errors?.required" class="alert alert-danger" style="margin-top:1%;">
                    Model girilmesi zorunludur.
                </div>
            </div>    
        </div>
        <div class="row">
            <div class="col-md-6">
                <label>Telefon Numarası</label>
                <input class="form-control" type="text" formControlName="phoneNumber">
                <div *ngIf="(isSubmitted || addReservationForm.controls.phoneNumber.dirty || addReservationForm.controls.phoneNumber.touched) && addReservationForm.controls.phoneNumber.errors?.required" class="alert alert-danger" style="margin-top:1%;">
                    Telefon numarası girilmesi zorunludur.
                </div>
                <div *ngIf="(isSubmitted || addReservationForm.controls.phoneNumber.dirty || addReservationForm.controls.phoneNumber.touched) && (addReservationForm.controls.phoneNumber.errors?.maxlength || addReservationForm.controls.phoneNumber.errors?.minlength)" class="alert alert-danger" style="margin-top:1%;">
                    Telefon numarası 11 hane olmalıdır.
                </div>
            </div>
            <div class="col-md-6">
                <label>Notlar</label>
                <textarea class="form-control" formControlName="homedescription"></textarea>
            </div>
        </div>
        <div class="row">
            <div>
                <button class="btn btn-success" style="float:right;margin-right:1.5%;margin-top:1%" (click)="addReservation()">Kaydet</button>
            </div>
        </div>
    </form>
</div>