
<div class="col-md-12">
    <h2>Araç Detay</h2>
    <hr />
    <form [formGroup]="addCarForm">
    <div class="row">
        <div class="col-md-6">
            <div class="form-check">
            <input class="form-check-input" type="checkbox" value="false" formControlName="isWantedOtoKuafor" disabled>
            <label class="form-check-label">Oto Kuaför istedi mi?</label>
            </div>
        </div>
                <div class="col-md-6">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="false" formControlName="isPeriodicMaintenance" disabled>
                <label class="form-check-label">Periyodik bakım mı?</label>
            </div>
        </div>   
    </div>
    <div *ngIf="showPayment" class="row">
        <div class="col-md-6">
            <label>Kredi Kartı Ödeme Tutarı</label>
            <input class="form-control" type="text" formControlName="creditCard" disabled>
        </div>
        <div class="col-md-6">
            <label>Nakit Ödeme Tutarı</label>
            <input class="form-control" type="text" formControlName="cash" disabled>
        </div>
    </div>
    <div class="row">
    <div class="col-md-6">
        <label>Ad Soyad</label>
        <input class="form-control" type="text" formControlName="fullname" disabled>
    </div>
    <div class="col-md-6">
            <label>Plaka</label>
            <input class="form-control" type="text" formControlName="plate" disabled>
    </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label>Marka</label>
            <input class="form-control" type="text" formControlName="make" disabled>
        </div>
        <div class="col-md-6">
            <label>Model</label>
            <input class="form-control" type="text" formControlName="model" disabled>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label>Renk</label>
            <input class="form-control" type="text" formControlName="color" disabled>
        </div>
        <div class="col-md-6">
            <label>Alım Tarihi</label>            
            <input matInput class="form-control" formControlName="purchaseDate" disabled>        
        </div>
    </div>
    <div class="row">    
        <div class="col-md-6">
            <label>Teslim Tarihi</label>
            <input matInput class="form-control" formControlName="completeDate" disabled>
        </div>
        <div class="col-md-6">
            <label>Telefon Numarası</label>
            <input class="form-control" type="text" formControlName="phoneNumber" disabled>
        </div>
    </div>
    <div class="row">       
        <div class="col-md-6">
            <label>Alınacak Tutar</label>
            <input class="form-control" type="text" formControlName="income" disabled>
        </div>
        <div class="col-md-6">
            <label>İndirimli Tutar</label>
            <input class="form-control" type="text" formControlName="discountedIncome" disabled>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label>Yapılacak İşlemler</label>
            <div style="display:flex;">
            <input class="form-control" type="text" placeholder="İşlem" formControlName="description" disabled>
            <input class="form-control" type="number" placeholder="Masraf" value="" formControlName="expense" disabled>
            <input class="form-control" type="number" placeholder="Alınacak Tutar" value="" formControlName="operationIncome" disabled>
            <button class="btn btn-secondary" disabled>Ekle</button>
            </div>
            <div *ngFor="let item of operations">
                <div style="display:flex;margin-top:1%;">
            <input class="form-control" type="text" placeholder="İşlem" value="{{item.description}}" disabled>
            <input class="form-control" type="text" placeholder="Tutar" value="{{item.expense}} TL" disabled>
            <input class="form-control" type="text" placeholder="Alınacak Tutar" value="{{item.operationIncome}} TL" disabled>
            <button class="btn btn-danger" disabled>X</button>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <label>Notlar</label>
            <textarea class="form-control" formControlName="homedescription" disabled></textarea>
        </div>
    </div>
    </form>
</div>