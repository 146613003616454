
<br />
<h2>Ay Sonu Rapor</h2>
<hr />

<div class="container content">
    <form [formGroup]="endOfMonthReportForm">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-3">
            <label class="lbl">Rapor Tarihi</label>
        </div>
        <div class="col-md-6" style="display:flex;">
            <select class="form-control" formControlName="reportMonth">
                <option value="1">Ocak</option>
                <option value="2">Şubat</option>
                <option value="3">Mart</option>
                <option value="4">Nisan</option>
                <option value="5">Mayıs</option>
                <option value="6">Haziran</option>
                <option value="7">Temmuz</option>
                <option value="8">Ağustos</option>
                <option value="9">Eylül</option>
                <option value="10">Ekim</option>
                <option value="11">Kasım</option>
                <option value="12">Aralık</option>
            </select>
            <select formControlName="reportYear" class="form-control" style="margin-left:1%;" id="selectyear"></select> 
        </div>
        <div class="col-md-1"></div>
    </div>
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-3">
            <label class="lbl">Kira Tutarı</label>
        </div>
        <div class="col-md-6">
            <input formControlName="rentPrice" class="form-control" type="number" value="0">
        </div>
        <div class="col-md-1"><label class="lbl">TL</label></div>
    </div>
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-3">
            <label class="lbl">Fatura Tutarı</label>
        </div>
        <div class="col-md-6">
            <input formControlName="billPrice" class="form-control" type="number" value="0">
        </div>
        <div class="col-md-1"><label class="lbl">TL</label></div>
    </div>
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-3">
            <label class="lbl">Çalışan Gideri</label>
        </div>
        <div class="col-md-6">
            <input formControlName="employeePrice" class="form-control" type="number" value="0">
        </div>
        <div class="col-md-1"><label class="lbl">TL</label></div>
    </div>
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-3">
            <label class="lbl">Ek Gider</label>
        </div>
        <div class="col-md-6">
            <input formControlName="adittionalExpense" class="form-control" type="number" value="0">
        </div>
        <div class="col-md-1"><label class="lbl">TL</label></div>
    </div>
    <div class="row">
        <div class="col-md-5"></div>        
        <div class="col-md-2">
            <span (click)="submitForm()" class="btn btn-secondary">Hesapla <i class="fa fa-calculator"></i></span>
        </div>
        <div class="col-md-5"></div>
    </div>
    </form>
    <div *ngIf="isSubmitted">
    <div class="row" style="color:white;">
        <hr />
    </div>
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-3">
            <label class="lbl">Toplam Kazanç</label>
        </div>
        <div class="col-md-6">
            <label class="lbl">{{result.totalIncome | currency:'TRY'}}</label>
        </div>
        <div class="col-md-1"></div>
    </div>
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-3">
            <label class="lbl">Toplam Gider</label>
        </div>
        <div class="col-md-6">
            <label class="lbl">{{result.totalExpense | currency:'TRY'}}</label>
        </div>
        <div class="col-md-1"></div>
    </div>
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-3">
            <label class="lbl">Net Kazanç</label>
        </div>
        <div class="col-md-6">
            <label class="lbl">{{result.profit | currency:'TRY'}}</label>
        </div>
        <div class="col-md-1"></div>
    </div>
    </div>
</div>