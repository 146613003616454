
<div class="row" style="display:flex;margin-top:3%;">
<div class="col-md-6">
<input type="text" class="form-control" placeholder="Öneri" [(ngModel)]="suggestionText">
</div>
<div class="col-md-6">
<button (click)="addSuggestion()" class="btn btn-success">Kaydet</button>
</div>
</div>
<div class="row" style="margin-top:1.5%;">
<table class="table">
<thead>
    <tr>
        <th>Öneri</th>
        <th>İşlemler</th>
    </tr>
</thead>
<tbody>
    <tr *ngFor="let item of suggestions">
        <td>{{item.suggestion}}</td>
        <td><button (click)="deleteSuggestion(item.suggestion)" class="btn btn-danger">X</button></td>
    </tr>
</tbody>
</table>
</div>