<div *ngIf="isLoggedIn">
<nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
            <!-- Navbar Brand-->
            <a class="navbar-brand ps-3" href="/arac-ekle">Borz Atölye</a>
            <button class="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle" href="#"><svg class="svg-inline--fa fa-bars fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg></button>       
</nav>
        <div id="layoutSidenav">
            <div id="layoutSidenav_nav">
                <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    <div class="sb-sidenav-menu">
                        <div class="nav">
                            <a class="nav-link" href="/arac-ekle">
                                <div class="sb-nav-link-icon"><i class="fas fa-plus"></i></div>
                                Araç Ekle
                            </a>
                            <a class="nav-link" href="/arac-listesi">
                                <div class="sb-nav-link-icon"><i class="fas fa-list"></i></div>
                                Araç Listesi
                            </a>
                           <a *ngIf="type" class="nav-link" href="/ay-bazli-rapor">
                                <div class="sb-nav-link-icon"><i class="far fa-file"></i></div>
                                Ay Bazlı Rapor
                            </a>
                            <a *ngIf="type" class="nav-link" href="/ay-sonu-rapor">
                                <div class="sb-nav-link-icon"><i class="far fa-file"></i></div>
                                Ay Sonu Rapor
                            </a>
                            <a class="nav-link" href="/rezervasyon-liste">
                                <div class="sb-nav-link-icon"><i class="fas fa-table"></i></div>
                                Rezervasyon Yönetimi
                            </a>
                            <span (click)="signOut()" class="nav-link">
                                <div class="sb-nav-link-icon"><i class="far fa-times-circle"></i></div>
                                Çıkış Yap
                            </span>
                        </div>
                    </div>
                    <div class="sb-sidenav-footer">
                    </div>
                </nav>
            </div>
            <div id="layoutSidenav_content">
                <main>
                    <div class="container-fluid px-4">
                        <router-outlet></router-outlet>
                    </div>
                </main>
                <footer class="py-4 bg-light mt-auto">
                    <div class="container-fluid px-4">
                        <div class="d-flex align-items-center justify-content-between small">                            
                        </div>
                    </div>
                </footer>
            </div>
        </div>
</div>
<div *ngIf="!isLoggedIn">
    <app-login></app-login>
</div>