import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    private httpClient: HttpClient
    ) { }


  private API_URL = environment.API_URL;

 

  isSubmitted: boolean = false;
  username: string = '';
  password: string = '';

  ngOnInit(): void {
    this.isLoggedIn();
  }

  signIn() : void {
    if(this.username !== "" && this.password !== "") {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");    
    this.httpClient.post(
      this.API_URL + "user/login",
      { username: this.username, password: this.password },
      { headers: headers }
    ).subscribe((res) => {
      if(res['status']) {
        localStorage.setItem("token", res['token']);
        localStorage.setItem("type", this.username === 'bahadir.ulusoy' ? "true" : "false");
        this.router.navigateByUrl("/arac-ekle");
        window.location.reload();
      } else {
        this.isSubmitted = true;
      }
    });
    }
  }


  isLoggedIn() : void {
    let token = localStorage.getItem("token");
    if(token !== null && token !== undefined && token !== "") {
      this.router.navigateByUrl("/arac-ekle");
    }
  }

}
