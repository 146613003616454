import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddCarComponent } from './add-car/add-car.component';
import { ColorSuggestionComponent } from './color-suggestion/color-suggestion.component';
import { DeliveryReceiptComponent } from './delivery-receipt/delivery-receipt.component';
import { DetailCarComponent } from './detail-car/detail-car.component';
import { EditCarComponent } from './edit-car/edit-car.component';
import { EndOfMonthReportComponent } from './end-of-month-report/end-of-month-report.component';
import { ListCarComponent } from './list-car/list-car.component';
import { LoginComponent } from './login/login.component';
import { MakeSuggestionComponent } from './make-suggestion/make-suggestion.component';
import { ModelSuggestionComponent } from './model-suggestion/model-suggestion.component';
import { MonthBasedReportComponent } from './month-based-report/month-based-report.component';
import { OfferFormComponent } from './offer-form/offer-form.component';
import { OperationSuggestionComponent } from './operation-suggestion/operation-suggestion.component';
import { ReservationAddComponent } from './reservation-add/reservation-add.component';
import { ReservationEditComponent } from './reservation-edit/reservation-edit.component';
import { ReservationListComponent } from './reservation-list/reservation-list.component';

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "arac-ekle", component: AddCarComponent },
  { path: "arac-listesi", component: ListCarComponent },
  { path: "arac-detay/:id", component: DetailCarComponent },
  { path: "teslim-makbuzu/:id", component: DeliveryReceiptComponent },
  { path: "teklif-formu/:id", component: OfferFormComponent },
  { path: "marka-oneri", component: MakeSuggestionComponent },
  { path: "model-oneri", component: ModelSuggestionComponent },
  { path: "renk-oneri", component: ColorSuggestionComponent },
  { path: "islem-oneri", component: OperationSuggestionComponent },
  { path: "arac-duzenle/:id", component: EditCarComponent },
  { path: "ay-bazli-rapor", component: MonthBasedReportComponent },
  { path: "ay-sonu-rapor", component: EndOfMonthReportComponent },
  { path: "rezervasyon-liste", component: ReservationListComponent },
  { path: "rezervasyon-ekle", component: ReservationAddComponent },
  { path: "rezervasyon-duzenle/:id", component: ReservationEditComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
