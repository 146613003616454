import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-offer-form',
  templateUrl: './offer-form.component.html',
  styleUrls: ['./offer-form.component.css']
})
export class OfferFormComponent implements OnInit {

  constructor(
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private router: Router
  ) { }

  private API_URL = environment.API_URL;

  car: Object;
  documentDate: String;
  fileName: string;

  ngOnInit(): void {

    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    this.httpClient.get(
      this.API_URL + "workshopCar" + "/detail/" + this.activatedRoute.snapshot.paramMap.get('id')
    ).subscribe((res) => {
      if(res && res[0] !== null) {
        this.car = res[0];
        this.documentDate = this.datepipe.transform(this.car['purchaseDate'], 'd MMMM y - HH:mm');
        this.fileName = this.car['plate'] + '-Teklif-Formu.pdf';
        setTimeout(()=>{
          this.generatePDF();
        }, 1500);
      } 
    });
  }

  generatePDF() {
    const div = document.getElementById("main");
    const options = {background: "white", height: div.clientHeight, width: div.clientWidth};

    html2canvas(div, options).then((canvas) => {

        let imgData = canvas.toDataURL("image/PNG");
        
        var imgWidth = 210; 
        var pageHeight = 295;  
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;

        //Initialize JSPDF
        let doc = new jsPDF("p", "mm", "a4");
        var position = 0;

        doc.addImage(imgData, 'PNG', 20, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        
     
        while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 20, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        break;
      }

        // Make file
        doc.save(this.fileName);
        this.router.navigateByUrl('/arac-listesi');

    });

  }

}
