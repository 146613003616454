import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import $ from 'jquery'; 
import select2 from 'select2';

@Component({
  selector: 'app-add-car',
  templateUrl: './add-car.component.html',
  styleUrls: ['./add-car.component.css']
})
export class AddCarComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
    private httpClient: HttpClient,
    private router: Router
    ) {
      this.dateAdapter.setLocale("tr");
    }

  private API_URL = environment.API_URL;

  isSubmitted: boolean = false;
  addCarForm: FormGroup;
  operations: Object[] = [];
  suggestions: Object = [];

  select2make: any;
  select2model: any;
  select2color: any;
  select2operation: any;

  ngOnInit(): void {
    window['$'] = window['jQuery'] = $;
    window['select2'] = select2;

    this.addCarForm = this.formBuilder.group({
      plate: ['', Validators.compose([Validators.required])],
      make: ['', Validators.compose([Validators.required])],
      kilometers: ['', Validators.compose([])],
      model: ['', Validators.compose([Validators.required])],
      color: ['', Validators.compose([Validators.required])],      
      fullname: ['', Validators.compose([Validators.required])],      
      purchaseDate: ['', Validators.compose([])],
      phoneNumber: ['', Validators.compose([Validators.required, Validators.maxLength(11), Validators.minLength(11)])],
      completeDate: ['', Validators.compose([])],
      income: ['', Validators.compose([])],
      discountedIncome: ['', Validators.compose([])],
      description: ['', Validators.compose([])],
      expense: ['', Validators.compose([])],
      operationIncome: ['', Validators.compose([])],
      isWantedOtoKuafor: ['', Validators.compose([])],
      homedescription: ['', Validators.compose([])],
      isPeriodicMaintenance: ['', Validators.compose([])]
    }); 
    this.getSuggestions();

    let isItemSelected = false;
    let fcname = '';

    $(document).on('mouseup', '.select2-results__option', (e) => {
      isItemSelected = true;   
      $('ng-select2[formcontrolname="'+fcname+'"]').val(e.currentTarget.innerText);
      $('ng-select2[formcontrolname="'+fcname+'"] span span span span.select2-selection__rendered').html(e.currentTarget.innerText);
      $('ng-select2[formcontrolname="'+fcname+'"]').change();

      if(fcname === 'make') {
        this.addCarForm.controls.make.setValue(e.currentTarget.innerText);    
      } else if(fcname === 'model') {
        this.addCarForm.controls.model.setValue(e.currentTarget.innerText); 
      } else if(fcname === 'color') {
        this.addCarForm.controls.color.setValue(e.currentTarget.innerText); 
      } else if(fcname === 'description') {
        this.addCarForm.controls.description.setValue(e.currentTarget.innerText); 
      }
    });

    $(document).on('click', 'ng-select2', function(e) {
      fcname = $(e.currentTarget).attr('formcontrolName');
    });

    $(document).on('keydown', 'input.select2-search__field', function(e) {
        if(e.key === 'Enter') {      
            isItemSelected = false;
            $(e.currentTarget).val($(e.currentTarget).val().toUpperCase());
            $('ng-select2[formcontrolname="'+fcname+'"] select').append(new Option($(e.currentTarget).val(), $(e.currentTarget).val(), false, true));
            $('ng-select2[formcontrolname="'+fcname+'"]').val($(e.currentTarget).val());
            $('ng-select2[formcontrolname="'+fcname+'"]').change();
        }
    });

    this.select2make = $('ng-select2[formcontrolname="make"]');

    this.select2make.on('change', () => {   
      if(!isItemSelected && $('.select2-search__field').val() !== null && $('.select2-search__field').val() !== undefined && $('.select2-search__field').val() !== '')
      this.addCarForm.controls.make.setValue($('.select2-search__field').val());           
    });

    this.select2model = $('ng-select2[formcontrolname="model"]');

    this.select2model.on('change', () => {  
      if(!isItemSelected && $('.select2-search__field').val() !== null && $('.select2-search__field').val() !== undefined && $('.select2-search__field').val() !== '')
      this.addCarForm.controls.model.setValue($('.select2-search__field').val());      
    });

    this.select2color = $('ng-select2[formcontrolname="color"]');

    this.select2color.on('change', () => {   
      if(!isItemSelected && $('.select2-search__field').val() !== null && $('.select2-search__field').val() !== undefined && $('.select2-search__field').val() !== '')
      this.addCarForm.controls.color.setValue($('.select2-search__field').val());     
    });

    this.select2operation = $('ng-select2[formcontrolname="description"]');

    this.select2operation.on('change', () => {
      if(!isItemSelected && $('.select2-search__field').val() !== null && $('.select2-search__field').val() !== undefined && $('.select2-search__field').val() !== '')
      this.addCarForm.controls.description.setValue($('.select2-search__field').val());            
    });
  }

  getSuggestions() {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    this.httpClient.get(
      this.API_URL + "workshopCar" + "/suggestions"
    ).subscribe((res: Object) => {
      this.suggestions = res;
    });
  }

  addOperation() {
    if(this.addCarForm.controls.description.value !== null && this.addCarForm.controls.description.value !== "" && this.addCarForm.controls.expense.value !== null && this.addCarForm.controls.operationIncome.value !== null) {
    this.operations.push({ description: this.addCarForm.controls.description.value.toUpperCase(), expense: this.addCarForm.controls.expense.value, operationIncome: this.addCarForm.controls.operationIncome.value });
    this.addCarForm.controls.description.setValue('');
    this.addCarForm.controls.expense.setValue('');
    this.addCarForm.controls.operationIncome.setValue('');
    let totalIncome = 0;
    this.operations.forEach((x) => {
        totalIncome += Number(x['operationIncome']);
    });
    this.addCarForm.controls.income.setValue(totalIncome);
    }
  }

  removeOperation(desc, operationIncome) {
    for(let i = 0; i < this.operations.length; i++) {
      if(this.operations[i]['description'] == desc && this.operations[i]['operationIncome'] == operationIncome) {
        this.addCarForm.controls.income.setValue(this.addCarForm.controls.income.value - this.operations[i]['operationIncome']);
        this.operations.splice(i, 1);
        break;
      }
    }
  }

  addCar() {  
    if(this.operations === null || this.operations.length === 0) {
      alert("İşlem girilmesi zorunludur.");
    } else {
      if(this.addCarForm.valid) {
        if(this.addCarForm.controls.income.value === null || this.addCarForm.controls.income.value === "") {
          alert("Alınacak tutar alanı zorunludur.");
        } else if(this.addCarForm.controls.discountedIncome.value === null || this.addCarForm.controls.discountedIncome.value === "") {
          alert("İndirimli tutar alanı zorunludur.");
        } else {
            let totalExpense = 0;
            this.operations.forEach((x) => {
              totalExpense += Number(x['expense']);
            });
            let prft = Number(this.addCarForm.controls.discountedIncome.value) - totalExpense;

            let headers = new HttpHeaders();
            headers = headers.append("Content-Type", "application/json");    
            // headers = headers.append("Authorization", "Bearer " + localStorage.getItem('token'));    
            this.httpClient.post(
              this.API_URL + "workshopCar",
              { 
              plate: this.addCarForm.controls.plate.value.replace(/\s/g, "").toUpperCase(),
              fullname: this.addCarForm.controls.fullname.value.toUpperCase(),
              make: this.addCarForm.controls.make.value.toUpperCase(),
              model: this.addCarForm.controls.model.value.toUpperCase(),
              color: this.addCarForm.controls.color.value.toUpperCase(),
              purchaseDate: new Date(),
              phoneNumber: this.addCarForm.controls.phoneNumber.value,
              kilometers: this.addCarForm.controls.kilometers.value,
              income: this.addCarForm.controls.income.value,
              discountedIncome: this.addCarForm.controls.discountedIncome.value,
              operations: this.operations,
              profit: prft,
              expense: totalExpense,
              isWantedOtoKuafor: this.addCarForm.controls.isWantedOtoKuafor.value,
              isPeriodicMaintenance: this.addCarForm.controls.isPeriodicMaintenance.value,
              description: this.addCarForm.controls.homedescription.value
              }, 
              { headers: headers }
            ).subscribe((res) => {
              alert("Araç başarıyla eklendi.");
              this.router.navigateByUrl("/arac-listesi");
            });
        }
      } else {
        this.isSubmitted = true;
        console.log(this.addCarForm.errors);
      }
    }
  }

}
