import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reservation-list',
  templateUrl: './reservation-list.component.html',
  styleUrls: ['./reservation-list.component.css']
})
export class ReservationListComponent implements OnInit {

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) { }

  private API_URL = environment.API_URL;

  reservations: Object[];

  ngOnInit(): void {
    this.getReservations();
  }



  getReservations() {
    this.httpClient.get(
      this.API_URL + "reservation"
    ).subscribe((res: Object[]) => {
     
      let sorts =   res.sort((a: any, b: any) => {
        return <any>new Date(b.reservation?.reservationDate) - <any>new Date(a.reservation?.reservationDate);
      });

      sorts.map((el, index)=>{
        el['id'] = sorts.length - index- 1;
      })
      this.reservations = sorts;
    });
  }

  redirectToAdd() {
    this.router.navigateByUrl('/rezervasyon-ekle');
  }

  deleteReservation(id) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    
    this.httpClient.post(
          this.API_URL + "reservation/delete/" + id,
            { }, 
            { headers: headers }
      ).subscribe((res) => {
          alert("Rezervasyon başarıyla silindi.");
          window.location.reload();
      }); 
  }

}
