import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-delivery-receipt',
  templateUrl: './delivery-receipt.component.html',
  styleUrls: ['./delivery-receipt.component.css']
})
export class DeliveryReceiptComponent implements OnInit {

  constructor(
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private router: Router
  ) { }

  private API_URL = environment.API_URL;

  ngOnInit(): void {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    this.httpClient.get(
      this.API_URL + "workshopCar" + "/detail/" + this.activatedRoute.snapshot.paramMap.get('id')
    ).subscribe((res) => {
      if(res && res[0] !== null) {
        let html = `<html><head><meta http-equiv="Content-Type" content="text/html; charset=utf-8"></head><style>body{margin: 0px 0px 0px 0px; width:100%;}.main{font-family: Verdana, Arial, sans-serif; font-size: 11px;}.main2{font-family: Verdana, Arial, sans-serif; font-size: 12px;}.main3{font-family: Verdana, Arial, sans-serif; font-size: 15px;}.div{width: 100%; border-top: 0.5px solid #DDDDDD; border-bottom: 0.5px solid #DDDDDD; margin-top: 4px; margin-bottom: 4px;}</style><body><div class="div"><center><label class="main3">BORZ ATÖLYE</label></center> <br/> <center><label class="main2">BİZİ TERCİH ETTİĞİNİZ İÇİN TEŞEKKÜRLER</label></center> <br/> <center><label class="main">Kayıt No: ${this.activatedRoute.snapshot.paramMap.get('id')}</label></center> <center><label class="main">${res[0]['plate']}</label></center> <center><label class="main">${res[0]['make']}</label></center> <center><label class="main">${res[0]['model']}</label></center> <center><label class="main">${res[0]['fullname']}</label></center> <center><label class="main">${this.datepipe.transform(res[0]['purchaseDate'], 'd MMMM y - H:mm')}</label></center> <br/> <center><label class="main">Oto Kuaför ${res[0]['isWantedOtoKuafor'] === true ? 'istedi' : 'istemedi'}</label> <br/><br/> </center><center><label class="main">İrtibat Tel: 0532 151 2158</label></center> <br/> </div></body></html>`;

        let win = window.open('', 'Teslim Makbuzu');
        win.document.body.innerHTML = html;

      }

      this.router.navigateByUrl('/arac-listesi');
    });
  
  }

}
