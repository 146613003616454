

<div id="main" class="main">
    <div class="head">
        <img src="/assets/borzatolye.jpg" />
        <label class="date">{{ documentDate }}</label>
        <label class="title">TEKLİF FORMU</label>
    </div>    
    <div class="body">
        <div class="detail">
        <div class="col-md-12 row">
            <div class="col-md-6">
                <label>Ad Soyad:</label> <b><label style="margin-left:3%;">{{ car['fullname'] }}</label></b>
            </div>
            <div class="col-md-6">
                <label>Plaka:</label> <b><label style="margin-left:3%;">{{ car['plate'] }}</label></b>
            </div>
        </div>
        <div class="col-md-12 row">
            <div class="col-md-6">
                <label>Marka:</label> <b><label style="margin-left:3%;">{{ car['make'] }}</label></b>
            </div>
            <div class="col-md-6">
                <label>Model:</label> <b><label style="margin-left:3%;">{{ car['model'] }}</label></b>
            </div>
        </div>
        <div class="col-md-12 row">
            <div class="col-md-6">
                <label>Telefon Numarası:</label> <b><label style="margin-left:3%;">{{ car['phoneNumber'] }}</label></b>
            </div>
            <div class="col-md-6">
                <label>Renk:</label> <b><label style="margin-left:3%;">{{ car['color'] }}</label></b>
            </div>
        </div>
        </div>
        <table class="table tbl">
             <thead>
                 <tr>
                     <th>Yapılan İşlem</th>
                     <th>Alınan Tutar</th>
                 </tr>
             </thead>
             <tbody>
                 <tr *ngFor="let operation of car['operations']">
                     <td>{{operation.description}}</td>
                     <td>{{operation.operationIncome}} TL</td>
                 </tr>
                 <tr>
                     <td></td>
                     <td></td>
                 </tr>
                <tr *ngIf="(car['income'] - car['discountedIncome']) !== 0">
                    <td><b>Ara Toplam</b></td>
                    <td>{{ car['income'] }} TL</td>
                 </tr>
                 <tr *ngIf="(car['income'] - car['discountedIncome']) !== 0">
                    <td><b>İndirim</b></td>
                    <td>{{ (car['income'] - car['discountedIncome']) }} TL</td>
                 </tr>
                 <tr>
                    <td><b>Genel Toplam</b></td>
                    <td><b>{{ car['discountedIncome'] }} TL</b></td>
                 </tr>                 
             </tbody>
        </table>
        <div class="footer">
            <div class="item">
                <span class="btn"><i class="fas fa-map-marker-alt"></i></span>
                <label style="margin:5px;font-size:9px;padding:1px;text-align:center;margin-top:3%;">Çobançeşme Mah. Ergenekon Sk. Kıroğlu Apt. No:43/B Bahçelievler/İstanbul</label>
            </div>
            <div class="item" style="width:40%;">
                <span class="btn"><i class="fas fa-phone"></i></span>
                <label style="margin:5px;font-size:11px;padding:1px;text-align:center;margin-top:5%;">0212 551 7757 - 0532 151 21 58</label>
            </div>
            <div class="item">
                <span class="btn"><i class="fab fa-instagram"></i></span>
                <label>borzatolye</label>
            </div>
            <div class="item">
                <span class="btn"><i class="fas fa-link"></i></span>
                <label style="margin:5px;font-size:10px;padding:1px;text-align:center;margin-top:4%;">www.borzatolye.com borzatolye@gmail.com</label>
            </div>
        </div>
    </div>
</div>