
<div class="col-md-12">
    
    <div class="row">
        <div class="col-md-6">
            <h2>Rezervasyon Listesi</h2>
        </div>
        <div class="col-md-6">
            <button (click)="redirectToAdd()" class="btn btn-success" style="float:right;"><i class="fa fa-plus"></i> Rezervasyon Ekle</button>
        </div>            
    </div>
    <hr />

    <table class="table">
    <thead>
        <tr>
            <th>Id</th>
            <th>Tarih</th>
            <th>Tam Ad</th>
            <th>Plaka</th>
            <th>Marka</th>
            <th>Model</th>
            <th>Tel No</th>
            <th>Not</th>
            <th>Sms?</th>
            <th>İşlemler</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of reservations">
            <td>{{item.id}}</td>
            <td>{{item.reservation.reservationDate | date: 'd/M/yyyy H:m' }}</td>
            <td>{{item.reservation.fullname}}</td>
            <td>{{item.reservation.plate}}</td>
            <td>{{item.reservation.make}}</td>
            <td>{{item.reservation.model}}</td>
            <td>{{item.reservation.phoneNumber}}</td>
            <td>{{item.reservation.description}}</td>
            <td *ngIf="item.reservation.isCompleted">Evet</td>
            <td *ngIf="!item.reservation.isCompleted">Hayır</td>
            <td>
                <button (click)="deleteReservation(item._id)" class="btn btn-danger"><i class="far fa-trash-alt"></i></button>
            </td>
        </tr>
    </tbody>
    </table>

</div>
