
<div [ngStyle]="{'display': (isLoading) ? 'block' : 'none'}">
<mat-spinner></mat-spinner>
</div>
<div class="col-md-12" [ngStyle]="{'display': (isLoading) ? 'none' : 'block'}">

<h2>Araç Listesi</h2>
<hr />


<div class="filtermenu">
<input [(ngModel)]="plateFilterText" class="form-control" placeholder="Plaka">
<input [(ngModel)]="makeFilterText" class="form-control" placeholder="Marka">
<input [(ngModel)]="modelFilterText" class="form-control" placeholder="Model">
<button (click)="filterData()" class="btn btn-primary"><i class="fa fa-search"></i></button>
</div>


<table class="table">
<thead>
    <tr>
        <th>Müşteri</th>
        <th>Plaka</th>
        <th>Marka</th>
        <th>Model</th>
        <th>Renk</th>
        <th>Alım Tarihi</th>
        <th>Teslim Tarihi</th>
        <th *ngIf="type">Masraf</th>
        <th *ngIf="type">Tutar</th>
        <th *ngIf="type">Kar</th>
        <th>KM</th>
        <th>İşlemler</th>
    </tr>
</thead>
<tbody>
    <tr *ngFor="let item of filteredCars">
        <td>{{item.fullname}}</td>
        <td>{{item.plate}}</td>
        <td>{{item.make}}</td>
        <td>{{item.model}}</td>
        <td>{{item.color}}</td>
        <td>{{item.purchaseDate | date:'mediumDate'}}</td>
        <td>{{item.completeDate | date:'mediumDate'}}</td>
        <td *ngIf="type && item.expense !== null">{{item.expense | currency:'TRY'}}</td>
        <td *ngIf="type && item.expense === null"></td>
        <td *ngIf="type && item.income !== null">{{item.discountedIncome | currency:'TRY'}}</td>
        <td *ngIf="type && item.income === null"></td>
        <td *ngIf="type && item.profit !== null">{{item.profit | currency:'TRY'}}</td>
        <td>{{item.kilometers}}</td>
        <td *ngIf="type && item.profit === null"></td>
        <td>
            <span (click)="redirectToInfo(item.idNumber)" class="btn btn-primary"><i class="fa fa-info"></i></span>
            <span *ngIf="item.completeDate === null" (click)="redirectToEdit(item.idNumber)" class="btn btn-warning"><i class="far fa-edit"></i></span>            
            <span (click)="redirectToDeliveryReceipt(item.idNumber)" class="btn btn-secondary"><i class="fa fa-file-invoice"></i></span>
            <span (click)="redirectToOfferForm(item.idNumber)" class="btn btn-secondary"><i class="fa fa-file-pdf"></i></span>
            <span (click)="makeReady(item._id)" *ngIf="!item.isReady" class="btn btn-warning"><i class="far fa-clock"></i></span>
            <span (click)="makeComplete(item._id, item.idNumber)" *ngIf="item.isReady && item.completeDate === null" class="btn btn-success"><i class="fa fa-check"></i></span>
        </td> 
    </tr>
</tbody>
</table>

</div>