import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { AddCarComponent } from './add-car/add-car.component';
import { LayoutComponent } from './layout/layout.component';
import { ListCarComponent } from './list-car/list-car.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { DatePipe, registerLocaleData } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import { DetailCarComponent } from './detail-car/detail-car.component';
import { DeliveryReceiptComponent } from './delivery-receipt/delivery-receipt.component';
import { OfferFormComponent } from './offer-form/offer-form.component';
import { NgSelect2Module } from 'ng-select2';
import { MakeSuggestionComponent } from './make-suggestion/make-suggestion.component';
import { ModelSuggestionComponent } from './model-suggestion/model-suggestion.component';
import { ColorSuggestionComponent } from './color-suggestion/color-suggestion.component';
import { OperationSuggestionComponent } from './operation-suggestion/operation-suggestion.component';
import { EditCarComponent } from './edit-car/edit-car.component';
import { MonthBasedReportComponent } from './month-based-report/month-based-report.component';
import { EndOfMonthReportComponent } from './end-of-month-report/end-of-month-report.component';
import { ReservationListComponent } from './reservation-list/reservation-list.component';
import { ReservationAddComponent } from './reservation-add/reservation-add.component';
import { ReservationEditComponent } from './reservation-edit/reservation-edit.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

registerLocaleData(localeTr);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AddCarComponent,
    LayoutComponent,
    ListCarComponent,
    DetailCarComponent,
    DeliveryReceiptComponent,
    OfferFormComponent,
    MakeSuggestionComponent,
    ModelSuggestionComponent,
    ColorSuggestionComponent,
    OperationSuggestionComponent,
    EditCarComponent,
    MonthBasedReportComponent,
    EndOfMonthReportComponent,
    ReservationListComponent,
    ReservationAddComponent,
    ReservationEditComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    NgSelect2Module,
    NgbModule
  ],
  providers: [MatDatepickerModule, { provide: LOCALE_ID, useValue: "tr-tr" }, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
