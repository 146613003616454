import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-operation-suggestion',
  templateUrl: './operation-suggestion.component.html',
  styleUrls: ['./operation-suggestion.component.css']
})
export class OperationSuggestionComponent implements OnInit {

 constructor(
    private httpClient: HttpClient,
    private router: Router
  ) { }

  private API_URL = environment.API_URL;

  suggestionText: String = "";
  suggestions: Object[] = [];

  ngOnInit(): void {
    this.getSuggestions();
  }

  getSuggestions() {
    this.httpClient.get(
      this.API_URL + "workshopCar/" + "getSuggestion/" + "operation"
    ).subscribe((res: Object[]) => {
      this.suggestions = res;
    });
  }

  addSuggestion() {
    if(this.suggestionText !== null && this.suggestionText !== undefined && this.suggestionText !== "") {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");  
    
    this.httpClient.post(
      this.API_URL + "workshopCar" + "/addSuggestion",
      { suggestion: this.suggestionText.toUpperCase(), type: 'operation' },
      { headers }
    ).subscribe((res) => {
        alert('Öneri kaydedilmiştir.');
        window.location.reload();
    });
  }
  }

  deleteSuggestion(suggestion) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");  
    
    this.httpClient.post(
      this.API_URL + "workshopCar" + "/deleteSuggestion",
      { suggestion: suggestion.toUpperCase() },
      { headers }
    ).subscribe((res) => {
        alert('Öneri silinmiştir.');
        window.location.reload();
    });
  }

}
