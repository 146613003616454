import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detail-car',
  templateUrl: './detail-car.component.html',
  styleUrls: ['./detail-car.component.css']
})
export class DetailCarComponent implements OnInit {

  constructor(
        private formBuilder: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe
  ) { }

  private API_URL = environment.API_URL;

  showPayment: boolean = false;
  isSubmitted: boolean = false;
  addCarForm: FormGroup;
  operations: Object[] = [];

  ngOnInit(): void {
    this.addCarForm = this.formBuilder.group({
      plate: ['', Validators.compose([Validators.required])],
      fullname: ['', Validators.compose([Validators.required])], 
      make: ['', Validators.compose([Validators.required])],
      model: ['', Validators.compose([Validators.required])],
      color: ['', Validators.compose([Validators.required])],      
      purchaseDate: ['', Validators.compose([Validators.required])],
      phoneNumber: ['', Validators.compose([])],
      completeDate: ['', Validators.compose([])],
      income: ['', Validators.compose([])],
      discountedIncome: ['', Validators.compose([])],
      description: ['', Validators.compose([])],
      expense: ['', Validators.compose([])],
      operationIncome: ['', Validators.compose([])],
      isWantedOtoKuafor: ['', Validators.compose([])],
      homedescription: ['', Validators.compose([])],
      creditCard: ['', Validators.compose([])],
      cash: ['', Validators.compose([])],
      isPeriodicMaintenance: ['', Validators.compose([])]
    }); 

    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    this.httpClient.get(
      this.API_URL + "workshopCar" + "/detail/" + this.activatedRoute.snapshot.paramMap.get('id')
    ).subscribe((res) => {
      if(res && res[0] !== null) {
      this.addCarForm.controls.fullname.setValue(res[0]['fullname']);
      this.addCarForm.controls.discountedIncome.setValue(res[0]['discountedIncome'] + ' TL');
      this.addCarForm.controls.plate.setValue(res[0]['plate']);
      this.addCarForm.controls.make.setValue(res[0]['make']);
      this.addCarForm.controls.model.setValue(res[0]['model']);
      this.addCarForm.controls.color.setValue(res[0]['color']);
      this.addCarForm.controls.phoneNumber.setValue(res[0]['phoneNumber']);
      this.addCarForm.controls.isWantedOtoKuafor.setValue(res[0]['isWantedOtoKuafor']);
      this.addCarForm.controls.isPeriodicMaintenance.setValue(res[0]['isPeriodicMaintenance']);
      this.addCarForm.controls.homedescription.setValue(res[0]['description']);
      if(res[0]['purchaseDate'] && res[0]['purchaseDate'] !== null && res[0]['purchaseDate'] !== '' && res[0]['purchaseDate'].length > 0) {
       this.addCarForm.controls.purchaseDate.setValue(
       this.datepipe.transform(res[0]['purchaseDate'], 'dd.MM.yyyy'));
      }
      if(res[0]['completeDate'] && res[0]['completeDate'] !== null && res[0]['completeDate'] !== '' && res[0]['completeDate'].length > 0) {
        this.addCarForm.controls.completeDate.setValue(
        this.datepipe.transform(res[0]['completeDate'], 'dd.MM.yyyy'));
      }
      this.addCarForm.controls.income.setValue(res[0]['income'] + ' TL');

      if(res[0]['creditCard'] !== null && res[0]['creditCard'] !== undefined && res[0]['cash'] !== null && res[0]['cash'] !== undefined) {
        this.showPayment = true;
        this.addCarForm.controls.creditCard.setValue(res[0]['creditCard'] + ' TL');
        this.addCarForm.controls.cash.setValue(res[0]['cash'] + ' TL');
      }

      this.operations = res[0]['operations'];
      }
      
    });
  }

}
