
<div class="col-md-12">
    <h2>Araç Düzenle</h2>
    <hr />
    <form [formGroup]="addCarForm">
    <div class="row">
        <div class="col-md-6">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="false" formControlName="isWantedOtoKuafor">
                <label class="form-check-label">Oto Kuaför istedi mi?</label>
            </div>
        </div>   
        <div class="col-md-6">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="false" formControlName="isPeriodicMaintenance">
                <label class="form-check-label">Periyodik bakım mı?</label>
            </div>
        </div>        
    </div>
    <div class="row">
    <div class="col-md-6">
        <label>Ad Soyad</label>
            <input class="form-control" type="text" formControlName="fullname" style="text-transform: uppercase;">
            <div *ngIf="(isSubmitted || addCarForm.controls.fullname.dirty || addCarForm.controls.fullname.touched) && addCarForm.controls.fullname.errors?.required" class="alert alert-danger" style="margin-top:1%;">
                Ad Soyad girilmesi zorunludur.
        </div>
    </div>
    <div class="col-md-6">
            <label>Plaka</label>
            <input class="form-control" type="text" formControlName="plate" style="text-transform: uppercase;">
            <div *ngIf="(isSubmitted || addCarForm.controls.plate.dirty || addCarForm.controls.plate.touched) && addCarForm.controls.plate.errors?.required" class="alert alert-danger" style="margin-top:1%;">
                Plaka girilmesi zorunludur.
            </div>
    </div>
    </div>
    <div class="row">
        <!-- <div class="col-md-6">
            <label>Marka</label>
            <input class="form-control" type="text" formControlName="make" style="text-transform: uppercase;">
            <div *ngIf="(isSubmitted || addCarForm.controls.make.dirty || addCarForm.controls.make.touched) && addCarForm.controls.make.errors?.required" class="alert alert-danger" style="margin-top:1%;">
                Marka girilmesi zorunludur.
            </div>
        </div> -->
        <div class="col-md-6">
            <label>Marka</label>
            <ng-select2 [data]="suggestions['makeSuggestions']" style="width:100%;" formControlName="make" id="selectmake"></ng-select2>
            <div *ngIf="(isSubmitted && (addCarForm.controls.make.dirty || addCarForm.controls.make.touched) && addCarForm.controls.make.errors?.required)" class="alert alert-danger" style="margin-top:1%;">
                Marka girilmesi zorunludur.
            </div>
        </div>
        <div class="col-md-6">
            <label>Model</label>
            <ng-select2 [data]="suggestions['modelSuggestions']" style="width:100%;" formControlName="model"></ng-select2>
            <div *ngIf="(isSubmitted && (addCarForm.controls.model.dirty || addCarForm.controls.model.touched) && addCarForm.controls.model.errors?.required)" class="alert alert-danger" style="margin-top:1%;">
                Model girilmesi zorunludur.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label>Renk</label>
            <ng-select2 [data]="suggestions['colorSuggestions']" style="width:100%;" formControlName="color"></ng-select2>
            <div *ngIf="(isSubmitted && (addCarForm.controls.color.dirty || addCarForm.controls.color.touched) && addCarForm.controls.color.errors?.required)" class="alert alert-danger" style="margin-top:1%;">
                Renk girilmesi zorunludur.
            </div>
        </div>
        <div class="col-md-6">
            <label>kilometers</label>
            <input class="form-control" type="number" formControlName="kilometers">
        </div>


        <div class="col-md-6">
            <label>Telefon Numarası</label>
            <input class="form-control" type="text" formControlName="phoneNumber">
            <div *ngIf="(isSubmitted || addCarForm.controls.phoneNumber.dirty || addCarForm.controls.phoneNumber.touched) && addCarForm.controls.phoneNumber.errors?.required" class="alert alert-danger" style="margin-top:1%;">
                Telefon numarası girilmesi zorunludur.
            </div>
            <div *ngIf="(isSubmitted || addCarForm.controls.phoneNumber.dirty || addCarForm.controls.phoneNumber.touched) && (addCarForm.controls.phoneNumber.errors?.maxlength || addCarForm.controls.phoneNumber.errors?.minlength)" class="alert alert-danger" style="margin-top:1%;">
                Telefon numarası 11 hane olmalıdır.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label>Alınacak Tutar</label>
            <input class="form-control" type="number" formControlName="income" disabled>
        </div>

        <div class="col-md-6">
            <label>İndirimli Tutar</label>
            <input class="form-control" type="number" formControlName="discountedIncome">
        </div>

    </div>
    <div class="row">
        <div class="col-md-6">
            <label>Yapılacak İşlemler</label>
            <div style="display:flex;">
            <ng-select2 [data]="suggestions['operationSuggestions']" style="width:100%;" formControlName="description" placeholder="İşlem"></ng-select2>
            <input class="form-control" type="number" placeholder="Masraf" value="" formControlName="expense">
            <input class="form-control" type="number" placeholder="Alınacak Tutar" value="" formControlName="operationIncome">
            <button class="btn btn-secondary" (click)="addOperation()">Ekle</button>
            </div>
            <div *ngFor="let item of operations">
                <div style="display:flex;margin-top:1%;">
            <input class="form-control" type="text" placeholder="İşlem" value="{{item.description}}" disabled>
            <input class="form-control" type="text" placeholder="Masraf" value="{{item.expense}} TL" disabled>
            <input class="form-control" type="text" placeholder="Alınacak Tutar" value="{{item.operationIncome}} TL" disabled>
            <button class="btn btn-danger" (click)="removeOperation(item.description, item.operationIncome)">X</button>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <label>Notlar</label>
            <textarea class="form-control" formControlName="homedescription"></textarea>
        </div>
    </div>
    <div class="row" style="margin-top:1.5%;float:right;">
        <button class="btn btn-success" (click)="addCar()">Kaydet</button>
    </div>
    </form>
</div>