import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-car',
  templateUrl: './list-car.component.html',
  styleUrls: ['./list-car.component.css']
})
export class ListCarComponent implements OnInit {

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) { }

  isLoading: boolean = true;

  private API_URL = environment.API_URL;

  plateFilterText: string = "";
  makeFilterText: string = "";
  modelFilterText: string = "";

  cars: Object[];
  filteredCars: Object[];

  type: boolean;

  ngOnInit(): void {
    this.type = localStorage.getItem('type') === 'true' ? true : false;
    this.getCars();
  }


  getCars() {
    this.httpClient.get(
      this.API_URL + "workshopCar"
    ).subscribe((res: Object[]) => {
      this.cars = res;
      this.filteredCars = res;
      this.isLoading = false;
    });
  }

  filterData() {
    this.isLoading = true;
    if(this.plateFilterText === '' && this.makeFilterText === '' && this.modelFilterText === '') {
      this.filteredCars = this.cars;
    } else {

      this.filteredCars = [];
      this.filteredCars = this.cars.filter(x =>
          x['plate'].toLowerCase().replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '') == this.plateFilterText.toLowerCase().replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '') ||
          x['make'].toLowerCase().replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '') == this.makeFilterText.toLowerCase().replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '') || 
          x['model'].toLowerCase().replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '') == this.modelFilterText.toLowerCase().replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '')
        );
    }
    this.isLoading = false;
  }

  redirectToInfo(id) {
    this.router.navigateByUrl('/arac-detay/' + id);
  }

  redirectToEdit(id) {
    this.router.navigateByUrl('/arac-duzenle/' + id);
  }

  redirectToDeliveryReceipt(id) {
    this.router.navigateByUrl('/teslim-makbuzu/' + id);
  }

  redirectToOfferForm(id) {
    this.router.navigateByUrl('/teklif-formu/' + id);
  }

  makeReady(id) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");  
    
    this.httpClient.post(
      this.API_URL + "workshopCar" + "/makeReady",
      { _id: id },
      { headers }
    ).subscribe((res) => {
      let obj = this.cars.find(x => x['_id'] === id);
      obj['isReady'] = true;
      
      let index = this.cars.indexOf(obj);
      if(index !== -1) 
        this.cars[index] = obj;

      index = this.filteredCars.indexOf(obj);
      if(index !== -1)
        this.filteredCars[index] = obj;

        alert('Araç hazır durumuna getirilmiş ve müşteri bilgilendirilmiştir.');
    });
  }

  makeComplete(id, idNumber) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");  
    
    this.httpClient.get(
      this.API_URL + "workshopCar" + "/detail/" + idNumber
    ).subscribe((res) => {
      if(res && res[0] !== null) {
        let totalAmount = res[0]['discountedIncome'];
        let creditCard = Number(prompt('Kredi kartı ile ödeme tutarı', '0'));
        let cash = Number(prompt('Nakit ile ödeme tutarı', '0'));
        if(creditCard != null && cash != null) {
        if(totalAmount == (creditCard + cash)) {
          this.httpClient.post(
            this.API_URL + "workshopCar" + "/makeComplete",
            { _id: id, date: new Date(), creditCard: creditCard, cash: cash },
            { headers }
          ).subscribe((res) => {
          
            let obj = this.cars.find(x => x['_id'] === id);
            obj['completeDate'] = new Date();
      
            let index = this.cars.indexOf(obj);
            if(index !== -1) 
              this.cars[index] = obj;
        
            index = this.filteredCars.indexOf(obj);
            if(index !== -1)
              this.filteredCars[index] = obj;

            alert('Araç işlemleri tamamlanmıştır.');
          });
        } else {
          alert('Kredi kartı ve Nakit ödeme toplamı tutar ile eşit olmalıdır.');
        }
        }
      }
      
    });
  }

}
