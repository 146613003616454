import { Injectable } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";


@Injectable()
export class CustomDateFormatter {
  parse(value: string): NgbDateStruct
  {
    if (!value)
      return null
     let parts=value.split('/');
     return {year:+parts[0],month:+parts[1],day:+parts[2]} as NgbDateStruct

  }
  format(date: NgbDateStruct): string
  {
    return date? (date.day + '/' + date.month + '/' + date.year) :null;
  }
}